import React, { FC } from 'react';
import { DynamicExteriorImagePreviewProps } from './';

export const DynamicExteriorImagePreviewTemplate: FC<DynamicExteriorImagePreviewProps> = ({
    className,
    imageUrl,
    minHeight,
}) => {
    return (
        <div className={`${className} carImageWrap`}>
            <img src={imageUrl} />
        </div>
    );
};
