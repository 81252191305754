import { IAction, Redux } from '../redux.interface';
import { AnyAction } from 'redux';
import { AlertType } from '@components/Alert';
import { DefaultRootState } from 'react-redux';
import { MutableRefObject } from 'react';
import { ModalVersion } from '../../constants/main';

export type ScrollTarget = undefined | string | MutableRefObject<any>;
export interface IduckState {
    loading: number;
    optionsLoading: number;
    carteGriseLoading: boolean;
    isLoadingNow: boolean;
    scrollPosition: ScrollTarget;
    modalOpen: boolean;
    modalType: string;
    modalVersion: string;
    modalData: object;
    modalProperties: object;
    salesManType: SalesManTypes;
    modalCallbacks: IModalCallbacks;
    breadcrumbs: IBreadcrumbItem[];
    characteristicsOpen: boolean;
    alerts: IAlert[];
    carOptionsAccordionState?: ICarOptionsAccordionState;
    translationKeysView: boolean;
    devBarHidden?: boolean;
    isRouting: boolean;
    isNoCarError: boolean;
    isDealSaved: boolean;
    showBasketInHeader: boolean;
    showPxNegativeValueAlert: boolean;
    imageViewType: string;
}

export interface IAlert {
    id: string;
    content: React.ReactNode;
    type?: keyof typeof AlertType;
    timeout?: number;
    fixedOnTop?: boolean;
}

export interface IBreadcrumbItem {
    url: string;
    text: string;
    idx?: number;
    originalUrl?: string;
}

export enum CarOptionsAccordionStateEnum {
    colors = 'colors',
    motorization = 'motorization',
    interior = 'interior',
    options = 'options',
}

export enum SalesManTypes {
    default = 0,
    nsc = 1,
    dealer = 2,
}

export type ICarOptionsAccordionState = {
    [key in CarOptionsAccordionStateEnum]: boolean;
};

export interface IModalCallbacks {
    onClose?: () => void;
    openPreviousModalType?: string;
    data?: any;
    modalProperties?: {};
    onClick?: (status: string, ...args: any[]) => void;
}

export interface IUIDuck {
    name: string;
    MODAL_TYPES: {
        DELETE_DEAL: string;
        EXAMPLE: string;
        FINANCIAL_SUMMARY: string;
        INVALID_CONFIGURATION: string;
        PERSONALIZE_OFFER: string;
        FINANCIAL_SUMMARY_CONFIGURATION: string;
        MISSING_PARTS: string;
        FINANCE_LEGAL: string;
        LEAD_FORM: string;
        INCOMPLETE_PX: string;
        REMOVE_PX_CONFIRMATION: string;
        PAYMENT_MOCK: string;
        STOCK_CAR_UNAVAILABLE: string;
        FEATURES: string;
        TRIM_COMPARE: string;
        NEED_HELP: string;
        DEMO_VEHICLE_INFO: string;
        SAVE: string;
        SAVE_YOUR_PROJECT: string;
        FEATURE: string;
        FEATURE_SWITCHES: string;
        CAR_OPTION_DETAIL: string;
        DEALER_FEES_POSTAL_CODE: string;
        DEALER_FEES_POSTAL_CODE_ERROR: string;
        POSTAL_CODE: string;
        SHARE_CONFIGURATION: string;
        DISABLE_CHECKOUT: string;
        OFFER_EXPIRED: string;
        SESSION_CHANGE: string;
        LOGOUT: string;
        SHARE_CONFIGURATION_BASKET: string;
        ORDER_REVIEW_ERROR: string;
        SCRAPPAGE: string;
        FIND_DEALER: string;
        TIRES_DETAIL: string;
        ENGINE_COMPARE: string;
        SHARE_CONFIGURATION_DEALER_CODE: string;
        SHARE_CONFIGURATION_PERSONALIZE_OFFER: string;
        SHARE_OFFER_SUMMARY: string;
        DEALER_SEARCH: string;
        ORDER_REVIEW_REDIRECT_ERROR: string;
    };
    reducer: (state: IduckState, action: IAction) => any;
    setLoading: (loadingWeight: number) => IAction;
    setSalesManType: (payload: SalesManTypes) => IAction;
    setOptionsLoading: (loadingWeight: number) => IAction;
    resetLoading: () => void;
    setCarteGriseLoading: (isLoading: boolean) => IAction;
    toggleImageViewType: (imageViewType: string) => IAction;
    setIsLoadingNow: (isLoading: boolean) => IAction;
    openModal: (payload: {
        data: any;
        modalType: string;
        callbacks?: IModalCallbacks;
        modalVersion?: ModalVersion;
        modalProperties?: {
            withoutPadding?: boolean;
            fullWidthOnDesktop?: boolean;
            middleWidthOnDesktop?: boolean;
            centerModeOnDesktop?: boolean;
        };
    }) => IAction;
    setScrollPosition: (position: ScrollTarget) => IAction;
    closeModal: (modalType?: string) => AnyAction;
    openCharacteristics: (payload: { data: any; modalType: string }) => IAction;
    closeCharacteristics: () => AnyAction;
    toggleCharacteristics: () => AnyAction;
    addBreadcrumb: (breadcrumb: IBreadcrumbItem[]) => IAction;
    removeBreadcrumb: (ids: number[]) => IAction;
    replaceBreadcrumb: (breadcrumbs: IBreadcrumbItem[]) => IAction;
    getOwnState: (state: any) => IduckState;
    getBreadcrumbs: (state: any) => IBreadcrumbItem[];
    getSalesManType: (state: any) => SalesManTypes;
    getModalData: (state: any) => any;
    getModalCallbacks: (state: any) => IModalCallbacks;
    getModalType: (state: any) => string;
    getModalVersion: (state: any) => ModalVersion;
    getModalProperties: (state: any) => any;
    isModalOpen: (state: any) => boolean;
    areCharacteristicsOpen: (state: any) => boolean;
    isLoadingFullscreen: (state: any) => boolean;
    isLoading: (state: any) => boolean;
    getScrollPosition: (state: any) => any;
    getAlerts: (state: IduckState) => IAlert[];
    removeAlert: (id: string) => IAction;
    addAlert: (alert: Omit<IAlert, 'id'>) => IAction;
    setCarOptionsAccordionState: (payload: CarOptionsAccordionStateEnum) => IAction;
    getCarOptionsAccordionState: (state: DefaultRootState) => ICarOptionsAccordionState;
    setTranslationKeysViewer: (show: boolean) => AnyAction;
    hideDevBar: () => AnyAction;
    setIsDealSaved: (isDealSaved: boolean) => IAction<{ isDealSaved: boolean }>;
    setShowBasketInHeader: (showBasketInHeader: boolean) => IAction<{ showBasketInHeader: boolean }>;
    setIsRouting: (isRouting: boolean) => IAction<{ isRouting: boolean }>;
    getIsRouting: (state: Redux) => boolean;
    setIsNoCarError: (isNoCarError: boolean) => IAction<{ isNoCarError: boolean }>;
    getIsNoCarError: (state: Redux) => boolean;
    setShowPxNegativeValueAlert: (showAlert: boolean) => IAction;
    showPxNegativeValueAlert: (state: Redux) => boolean;
}
