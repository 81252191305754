import React from 'react';
import { BRAND } from '../../constants/main';
import { DynamicExteriorImagePreviewAC } from './AC/DynamicExteriorImagePreview';
import { DynamicExteriorImagePreviewOV } from './OV/DynamicExteriorImagePreview.OV';
import { DynamicExteriorImagePreviewAP } from './AP/DynamicExteriorImagePreview.AP';
import { DynamicExteriorImagePreviewDS } from './DS/DynamicExteriorImagePreview.DS';
import { DynamicExteriorImagePreviewStyled } from './DynamicExteriorImagePreviewStyled';
import { SC } from '../../styles/theme';

export interface DynamicExteriorImagePreviewProps extends SC {
    imageUrl: string;
    minHeight?: number;
}

const DynamicExteriorImagePreview = (props: DynamicExteriorImagePreviewProps) => {
    if (BRAND === 'AC') return <DynamicExteriorImagePreviewAC {...props} />;
    if (BRAND === 'OV') return <DynamicExteriorImagePreviewOV {...props} />;
    if (BRAND === 'AP') return <DynamicExteriorImagePreviewAP {...props} />;
    if (BRAND === 'DS') return <DynamicExteriorImagePreviewDS {...props} />;
    return <DynamicExteriorImagePreviewStyled {...props} />;
};

export default DynamicExteriorImagePreview;
