import { Dispatch } from 'redux';
import { v4 as uuid } from 'uuid';
import { createActionTypes } from '../createActionTypes';
import { IAction } from '../redux.interface';
import get from 'lodash/get';
import {
    IduckState,
    IBreadcrumbItem,
    IUIDuck,
    IAlert,
    CarOptionsAccordionStateEnum,
    ICarOptionsAccordionState,
    IModalCallbacks,
    SalesManTypes,
} from './ui.duck.interface';
import { rebuildBreadcrumbs } from '@utils/Breadcrumb.utils';
import { AlertType } from '@components/Alert';
import { ImageViewType } from '../../constants/enums';
import { ModalVersion } from '../../constants/main';

const UI: any = {
    name: 'UI',
};
UI.MODAL_TYPES = {
    DELETE_DEAL: 'DELETE_DEAL',
    EXAMPLE: 'EXAMPLE',
    FINANCIAL_SUMMARY: 'FINANCIAL_SUMMARY',
    FINANCIAL_SUMMARY_CONFIGURATION: 'FINANCIAL_SUMMARY_CONFIGURATION',
    INVALID_CONFIGURATION: 'INVALID_CONFIGURATION',
    PERSONALIZE_OFFER: 'PERSONALIZE_OFFER',
    MISSING_PARTS: 'MISSING_PARTS',
    FINANCE_LEGAL: 'FINANCE_LEGAL',
    LEAD_FORM: 'LEAD_FORM',
    PAYMENT_MOCK: 'PAYMENT_MOCK',
    INCOMPLETE_PX: 'INCOMPLETE_PX',
    REMOVE_PX_CONFIRMATION: 'REMOVE_PX_CONFIRMATION',
    STOCK_CAR_UNAVAILABLE: 'STOCK_CAR_UNAVAILABLE',
    FEATURES: 'FEATURES',
    TRIM_COMPARE: 'TRIM_COMPARE',
    NEED_HELP: 'NEED_HELP',
    DEMO_VEHICLE_INFO: 'DEMO_VEHICLE_INFO',
    SAVE: 'SAVE',
    SAVE_YOUR_PROJECT: 'SAVE_YOUR_PROJECT',
    FEATURE: 'FEATURE',
    FEATURE_SWITCHES: 'FEATURE_SWITCHES',
    CAR_OPTION_DETAIL: 'CAR_OPTION_DETAIL',
    DEALER_FEES_POSTAL_CODE: 'DEALER_FEES_POSTAL_CODE',
    DEALER_FEES_POSTAL_CODE_ERROR: 'DEALER_FEES_POSTAL_CODE_ERROR',
    SHARE_CONFIGURATION: 'SHARE_CONFIGURATION',
    POSTAL_CODE: 'POSTAL_CODE',
    DISABLE_CHECKOUT: 'DISABLE_CHECKOUT',
    OFFER_EXPIRED: 'OFFER_EXPIRED',
    SESSION_CHANGE: 'SESSION_CHANGE',
    LOGOUT: 'LOGOUT',
    SHARE_CONFIGURATION_BASKET: 'SHARE_CONFIGURATION_BASKET',
    ORDER_REVIEW_ERROR: 'ORDER_REVIEW_ERROR',
    SCRAPPAGE: 'SCRAPPAGE',
    FIND_DEALER: 'FIND_DEALER',
    TIRES_DETAIL: 'TIRES_DETAIL',
    ENGINE_COMPARE: 'ENGINE_COMPARE',
    SHARE_CONFIGURATION_DEALER_CODE: 'SHARE_CONFIGURATION_DEALER_CODE',
    SHARE_CONFIGURATION_PERSONALIZE_OFFER: 'SHARE_CONFIGURATION_PERSONALIZE_OFFER',
    SHARE_OFFER_SUMMARY: 'SHARE_OFFER_SUMMARY',
    DEALER_SEARCH: 'DEALER_SEARCH',
    ORDER_REVIEW_REDIRECT_ERROR: 'ORDER_REVIEW_REDIRECT_ERROR',
};
const initialState: IduckState = {
    loading: 0,
    optionsLoading: 0,
    carteGriseLoading: false,
    isLoadingNow: false,
    scrollPosition: undefined,
    modalOpen: false,
    modalType: '',
    modalVersion: '',
    modalData: {},
    modalProperties: {},
    salesManType: SalesManTypes.default,
    modalCallbacks: null,
    breadcrumbs: [{ idx: 0, text: 'Home', url: '', originalUrl: '' }],
    characteristicsOpen: false,
    alerts: [],
    carOptionsAccordionState: {
        [CarOptionsAccordionStateEnum.colors]: false,
        [CarOptionsAccordionStateEnum.motorization]: false,
        [CarOptionsAccordionStateEnum.interior]: false,
        [CarOptionsAccordionStateEnum.options]: false,
    },
    translationKeysView: false,
    devBarHidden: false,
    isRouting: false,
    isNoCarError: false,
    isDealSaved: false,
    showBasketInHeader: false,
    showPxNegativeValueAlert: false,
    imageViewType: ImageViewType.EXTERIOR,
};
const actionTypes: any = createActionTypes(
    {
        add_loading: 'add_loading',
        add_options_loading: 'add_options_loading',
        reset_loading: 'reset_loading',
        carte_grise_loading: 'carte_grise_loading',
        loading_now: 'loading_now',
        add_scrollposition: 'add_scrollposition',
        open_modal: 'open_modal',
        close_modal: 'close_modal',
        add_breadcrumb: 'add_breadcrumb',
        remove_breadcrumb: 'remove_breadcrumb',
        replace_breadcrumb: 'replace_breadcrumb',
        open_characteristics: 'open_characteristics',
        close_characteristics: 'close_characteristics',
        toggle_characteristics: 'toggle_characteristics',
        set_alerts: 'set_alerts',
        remove_alert: 'remove_alert',
        set_car_options_accordion_state: 'set_car_options_accordion_state',
        translation_key_viewer: 'translation_key_viewer',
        hideDevBar: 'hideDevBar',
        set_is_routing: 'set_is_routing',
        set_is_no_car_error: 'set_is_no_car_error',
        set_is_deal_saved: 'set_is_deal_saved',
        set_show_basket_in_header: 'set_show_basket_in_header',
        set_show_px_negative_value_alert: 'set_show_px_negative_value_alert',
        set_salesman_type: 'set_salesman_type',
        toggle_image_view_type: 'toggle_image_view_type',
    },
    UI.name
);

UI.reducer = (state: IduckState = initialState, action: IAction) => {
    switch (action.type) {
        case actionTypes.set_salesman_type: {
            return {
                ...state,
                salesManType: action.payload,
            };
        }
        case actionTypes.add_loading:
            return {
                ...state,
                loading: Math.max(0, action.payload + state.loading),
            };
        case actionTypes.add_options_loading:
            return {
                ...state,
                optionsLoading: Math.max(0, action.payload + state.optionsLoading),
            };
        case actionTypes.reset_loading:
            return {
                ...state,
                loading: 0,
            };
        case actionTypes.carte_grise_loading:
            return {
                ...state,
                carteGriseLoading: action.payload,
            };
        case actionTypes.toggle_image_view_type:
            return {
                ...state,
                imageViewType: action.payload,
            };
        case actionTypes.loading_now:
            return {
                ...state,
                isLoadingNow: action.payload,
            };
        case actionTypes.add_scrollposition:
            return {
                ...state,
                scrollPosition: action.payload,
            };
        case actionTypes.open_modal:
            return {
                ...state,
                modalOpen: true,
                modalType: get(action, 'payload.modalType', ''),
                modalData: get(action, 'payload.data', {}),
                modalVersion: get(action, 'payload.modalVersion', {}),
                modalProperties: get(action, 'payload.modalProperties', {}),
                modalCallbacks: get(action, 'payload.callbacks'),
            };
        case actionTypes.close_modal:
            const closeOnlyModalType = action.payload;

            if (closeOnlyModalType) {
                return {
                    ...state,
                    ...(state.modalType === closeOnlyModalType && {
                        modalOpen: false,
                        modalType: '',
                        modalVersion: '',
                        modalProperties: {},
                        modalData: {},
                        modalCallbacks: null,
                    }),
                };
            } else {
                return {
                    ...state,
                    modalOpen: false,
                    modalType: '',
                    modalVersion: '',
                    modalProperties: {},
                    modalData: {},
                    modalCallbacks: null,
                };
            }
        case actionTypes.open_characteristics:
            return {
                ...state,
                characteristicsOpen: true,
            };
        case actionTypes.close_characteristics:
            return {
                ...state,
                characteristicsOpen: false,
            };
        case actionTypes.toggle_characteristics:
            return {
                ...state,
                characteristicsOpen: !state.characteristicsOpen,
            };
        case actionTypes.add_breadcrumb:
            return {
                ...state,
                breadcrumbs: [...action.payload],
            };
        case actionTypes.remove_breadcrumb:
            return {
                ...state,
                breadcrumbs: [...action.payload],
            };
        case actionTypes.replace_breadcrumb:
            return {
                ...state,
                breadcrumbs: [...action.payload],
            };
        case actionTypes.set_alerts:
            return {
                ...state,
                alerts: action.payload,
            };
        case actionTypes.remove_alert:
            return {
                ...state,
                alerts: state.alerts.filter((alert: IAlert) => alert.id !== action.payload),
            };
        case actionTypes.set_car_options_accordion_state:
            const accordionState: CarOptionsAccordionStateEnum = action.payload;
            return {
                ...state,
                carOptionsAccordionState: {
                    ...state.carOptionsAccordionState,
                    [action.payload]: !state.carOptionsAccordionState[accordionState],
                },
            };
        case actionTypes.translation_key_viewer:
            return {
                ...state,
                translationKeysView: action.payload,
            };
        case actionTypes.hideDevBar:
            return {
                ...state,
                devBarHidden: true,
            };
        case actionTypes.set_show_basket_in_header:
            return {
                ...state,
                showBasketInHeader: action.payload.showBasketInHeader,
            };
        case actionTypes.set_is_deal_saved:
            return {
                ...state,
                isDealSaved: action.payload.isDealSaved,
            };
        case actionTypes.set_is_routing:
            return {
                ...state,
                isRouting: action.payload.isRouting,
            };
        case actionTypes.set_is_no_car_error:
            return {
                ...state,
                isNoCarError: action.payload.isNoCarError,
            };
        case actionTypes.set_show_px_negative_value_alert:
            return {
                ...state,
                showPxNegativeValueAlert: action.payload,
            };
        default:
            return state;
    }
};

UI.setLoading = (loadingWeight: number = 0): IAction => ({
    type: actionTypes.add_loading,
    payload: loadingWeight,
});

UI.setSalesManType = (payload: SalesManTypes): IAction => ({
    type: actionTypes.set_salesman_type,
    payload,
});

UI.setOptionsLoading = (loadingWeight: number = 0): IAction => ({
    type: actionTypes.add_options_loading,
    payload: loadingWeight,
});

UI.resetLoading = () => ({
    type: actionTypes.reset_loading,
});

UI.setCarteGriseLoading = (isLoading: boolean): IAction => ({
    type: actionTypes.carte_grise_loading,
    payload: isLoading,
});

UI.setIsLoadingNow = (isLoading: boolean): IAction => ({
    type: actionTypes.loading_now,
    payload: isLoading,
});

UI.setScrollPosition = (scrollPosition: any): IAction => ({
    type: actionTypes.add_scrollposition,
    payload: scrollPosition,
});

UI.toggleImageViewType = (imageViewType: string): IAction => ({
    type: actionTypes.toggle_image_view_type,
    payload: imageViewType,
});

UI.openModal = (payload: {
    data: {};
    modalType: string;
    callbacks?: IModalCallbacks;
    modalVersion?: ModalVersion;
    modalProperties?: {
        withoutPadding?: boolean;
        fullWidthOnDesktop?: boolean;
        middleWidthOnDesktop?: boolean;
        centerModeOnDesktop?: boolean;
    };
}): IAction => ({
    type: actionTypes.open_modal,
    payload,
});
UI.closeModal = (modalType?: string) => ({
    type: actionTypes.close_modal,
    payload: modalType,
});

UI.openCharacteristics = (payload: { data: {}; modalType: string }): IAction => ({
    type: actionTypes.open_characteristics,
    payload,
});

UI.closeCharacteristics = () => ({
    type: actionTypes.close_characteristics,
});

UI.toggleCharacteristics = () => ({
    type: actionTypes.toggle_characteristics,
});

UI.addBreadcrumb =
    (breadcrumbs: IBreadcrumbItem[]): any =>
    (dispatch: Dispatch, getState: any) => {
        const statecrumbs = UI.getBreadcrumbs(getState());
        // Store original URLs
        let newBreadcrumbs = breadcrumbs.map((x) => {
            x.originalUrl = x.url;
            return x;
        });
        newBreadcrumbs = rebuildBreadcrumbs([...statecrumbs, ...newBreadcrumbs]);
        dispatch({
            type: actionTypes.add_breadcrumb,
            payload: newBreadcrumbs,
        });
    };

UI.removeBreadcrumb =
    (ids: number[]): any =>
    (dispatch: any, getState: any) => {
        const statecrumbs = UI.getBreadcrumbs(getState());
        let newBredcrumbs = statecrumbs.filter((b: IBreadcrumbItem) => !ids.includes(b.idx || 0));

        newBredcrumbs = rebuildBreadcrumbs(newBredcrumbs);
        dispatch({
            type: actionTypes.remove_breadcrumb,
            payload: newBredcrumbs,
        });
    };

UI.replaceBreadcrumb = (breadcrumbs: IBreadcrumbItem[]) => (dispatch: any, getState: any) => {
    const statecrumbs = UI.getBreadcrumbs(getState());
    // Store original URLs
    const replacecrumbs = breadcrumbs.map((x) => {
        x.originalUrl = x.url;
        return x;
    });
    let newBredcrumbs = statecrumbs.map((b: IBreadcrumbItem, indx: number) => {
        if (replacecrumbs.some((bc: IBreadcrumbItem) => bc.idx === b.idx)) {
            const searchIndex = b.idx !== undefined ? b.idx : indx;
            return replacecrumbs.find((b: IBreadcrumbItem) => b.idx === searchIndex);
        } else {
            return b;
        }
    });
    newBredcrumbs = rebuildBreadcrumbs(newBredcrumbs);
    dispatch({
        type: actionTypes.replace_breadcrumb,
        payload: newBredcrumbs,
    });
};

UI.addAlert =
    (payload: Omit<IAlert, 'id'>): any =>
    (dispatch: Dispatch, getState: any) => {
        const stateAlerts = UI.getAlerts(getState());
        const id: string = uuid();

        dispatch({
            type: actionTypes.set_alerts,
            payload: [...stateAlerts, { id, fixedOnTop: true, type: AlertType.SUCCESS, ...payload }],
        });

        setTimeout(() => {
            dispatch({
                type: actionTypes.remove_alert,
                payload: id,
            });
        }, payload.timeout || 5000);
    };

UI.removeAlert = (payload: string): IAction => ({
    type: actionTypes.remove_alert,
    payload,
});
UI.setTranslationKeysViewer = (show: boolean) => ({
    type: actionTypes.translation_key_viewer,
    payload: show,
});

UI.hideDevBar = () => ({
    type: actionTypes.hideDevBar,
    payload: true,
});

UI.setCarOptionsAccordionState = (accordionState: any): IAction => {
    return {
        type: actionTypes.set_car_options_accordion_state,
        payload: accordionState,
    };
};

UI.setIsRouting = (isRouting: boolean) => ({
    type: actionTypes.set_is_routing,
    payload: { isRouting },
});

UI.setIsNoCarError = (isNoCarError: boolean) => ({
    type: actionTypes.set_is_no_car_error,
    payload: { isNoCarError },
});

UI.setShowBasketInHeader = (showBasketInHeader: boolean) => ({
    type: actionTypes.set_show_basket_in_header,
    payload: { showBasketInHeader },
});

UI.setIsDealSaved = (isDealSaved: boolean) => ({
    type: actionTypes.set_is_deal_saved,
    payload: { isDealSaved },
});

UI.setShowPxNegativeValueAlert = (showAlert: boolean) => ({
    type: actionTypes.set_show_px_negative_value_alert,
    payload: showAlert,
});

UI.getOwnState = (state: any): IduckState => state[UI.name] || initialState;
UI.getBreadcrumbs = (state: any): IBreadcrumbItem[] => UI.getOwnState(state).breadcrumbs;
UI.getSalesManType = (state: any): SalesManTypes => UI.getOwnState(state).salesManType;
UI.getModalData = (state: any): any => UI.getOwnState(state).modalData;
UI.getModalCallbacks = (state: any): any => UI.getOwnState(state).modalCallbacks;
UI.getModalType = (state: any): string => UI.getOwnState(state).modalType;
UI.getModalVersion = (state: any): string => UI.getOwnState(state).modalVersion;
UI.getModalProperties = (state: any): any => UI.getOwnState(state).modalProperties;
UI.isModalOpen = (state: any): boolean => UI.getOwnState(state).modalOpen;
UI.areCharacteristicsOpen = (state: any): boolean => UI.getOwnState(state).characteristicsOpen;
UI.isLoadingFullscreen = (state: any): boolean => UI.getOwnState(state).loading > 1;
UI.isLoading = (state: any): boolean => !!UI.getOwnState(state).loading;
UI.showBasketInHeader = (state: any): boolean => UI.getOwnState(state).showBasketInHeader;
UI.isOptionsLoading = (state: any): boolean => !!UI.getOwnState(state).optionsLoading;
UI.getScrollPosition = (state: any): any => UI.getOwnState(state).scrollPosition;
UI.getAlerts = (state: IduckState): IAlert[] => UI.getOwnState(state).alerts;
UI.getCarOptionsAccordionState = (state: any): ICarOptionsAccordionState =>
    UI.getOwnState(state).carOptionsAccordionState;
UI.translationKeysView = (state: any): boolean => UI.getOwnState(state).translationKeysView;
UI.getIsRouting = (state: IduckState): boolean => UI.getOwnState(state).isRouting;
UI.getIsNoCarError = (state: IduckState): boolean => UI.getOwnState(state).isNoCarError;
UI.showPxNegativeValueAlert = (state: IduckState): boolean => UI.getOwnState(state).showPxNegativeValueAlert;

export const UIDuck: IUIDuck = UI;
export default UIDuck;
