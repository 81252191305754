import styled from 'styled-components';
import PriceBoxTemplate from './PriceBoxTemplate';
import { rem } from 'polished';

export const PriceBoxStyled = styled(PriceBoxTemplate)`
    height: 100%;

    .currencyLabel__suffix--OTR,
    .currencyLabel__suffix--TTC,
    .currencyLabel__suffix--TTC-monthly {
        font-size: ${rem(8)};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        text-transform: uppercase;
        margin: 0;
        padding: 0 ${rem(5)} 0 0;
    }

    .currencyLabel__suffix--OTR,
    .currencyLabel__suffix--TTC {
        margin: 0 0 0 ${rem(3)};
    }

    .catalogPrice {
        min-height: ${rem(28)}; /* Display catalogue price box even when no catalogue price is available */
        background-color: ${({ theme }) => theme.colors.white};
        padding: ${rem(5)} 0;

        .catalogPriceLabel {
            font-size: ${({ theme }) => theme.fontSizes.textTiny};
            font-weight: ${({ theme }) => theme.fontWeights.textLight};
            margin-right: ${rem(5)};
        }
        .catalogPriceAmount {
            font-size: ${({ theme }) => theme.fontSizes.textTiny};
            font-weight: ${({ theme }) => theme.fontWeights.textRegular};
        }

        .currencyLabel__suffix--OTR  {
            font-weight: ${({ theme }) => theme.fontWeights.textRegular};
        }
    }

    .aprValue {
        font-weight: ${({ theme }) => theme.fontWeights.textExtraBold};
        margin-bottom: 0;
    }
    .aprDescription {
        font-weight: ${({ theme }) => theme.fontWeights.textRegular};
        margin-top: ${rem(10)};
        margin-bottom: 0;
        cursor: pointer;
        font-size: ${({ theme }) => theme.fontSizes.textBase};
        color: ${({ theme }) => theme.colors.primary};
        text-decoration: none;
        strong {
            font-family: normal;
        }
        &:hover {
            text-decoration: underline;
        }
    }

    .demoLabel {
        display: flex;
        align-items: center;
        gap: ${rem(6)};

        svg {
            cursor: pointer;
        }
    }

    .priceBoxWrapper {
        padding: ${rem(16)};

        .financeLabel {
            font-size: ${({ theme }) => theme.fontSizes.textTiny};
            font-weight: ${({ theme }) => theme.fontWeights.textRegular};

            .specialPriceLabel {
                font-weight: ${({ theme }) => theme.fontWeights.textRegular};
            }

            .priceLabel {
                margin-right: ${rem(3)};
            }
        }

        .price {
            .cashPrice {
                font-size: ${({ theme }) => theme.fontSizes.textMedium};
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
                margin-top: ${rem(10)};
            }

            .showPriceExcludedVAT {
                margin-bottom: ${rem('10px')};
            }

            .priceConjunction {
                margin-top: ${rem(7)};

                span {
                    font-size: ${({ theme }) => theme.fontSizes.textTiny};
                    font-weight: ${({ theme }) => theme.fontWeights.textLight};
                    line-height: ${({ theme }) => theme.lineHeights.textSmall};
                    text-transform: uppercase;
                }
            }

            .monthlyPrice {
                font-size: ${({ theme }) => theme.fontSizes.textMedium};
                font-weight: ${({ theme }) => theme.fontWeights.textBold};
                margin: ${rem(10)} 0;
                white-space: nowrap;

                .infoIcon {
                    display: inline-flex;
                    height: ${rem(18)};

                    .vertical {
                        margin: 0;
                        height: 16px;
                    }

                    svg {
                        line,
                        circle {
                            stroke: ${({ theme }) => theme.colors.primary};
                        }
                    }
                }
            }
        }

        .priceNotice {
            font-size: ${({ theme }) => theme.fontSizes.textTiny};
            font-weight: ${({ theme }) => theme.fontWeights.textLight};

            .promotionalTextWrapper {
                display: flex;
                flex-direction: column;
                word-break: break-all;
            }

            .amount {
                font-weight: ${({ theme }) => theme.fontWeights.textRegular};
            }

            .label {
                font-weight: ${({ theme }) => theme.fontWeights.textRegular};
                display: block;
                text-align: left;
            }

            span {
                line-height: ${rem(14)};
            }
        }
    }
`;
