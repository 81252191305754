import styled, { css } from 'styled-components';
import { DynamicExteriorImagePreviewTemplate } from './DynamicExteriorImagePreviewTemplate';
import { rem } from 'polished';

export const DynamicExteriorImagePreviewStyled = styled(DynamicExteriorImagePreviewTemplate)`
    position: relative;
    width: 100%;
    height: 100%;

    img {
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    ${({ minHeight }) =>
        minHeight &&
        css`
            min-height: ${rem(minHeight)};
        `}
`;
