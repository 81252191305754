import React, { cloneElement } from 'react';
import Head from 'next/head';
import Header from '@components/v2/Header';
import Footer from '@components/Footer';
import Modal from '@components/Modal';
import Alert from '@components/Alert';
import LanguageSwitcher from '@components/LanguageSwitcher';
import { IFooterItem, IHeaderItem } from '../../redux/content/content.duck.interface';
import { SC } from '../../styles/theme';
import { BRAND, isBrandOV, isMarketGB, MULTI_LANGUAGES_ENABLED } from '../../constants/main';
import LanguageControls from '../LanguageControls';
import useTranslations from '@hooks/useTranslations';
import { connect } from 'react-redux';
import ContentDuck from '../../redux/content/content.duck';
import UIDuck from '../../redux/commonDucks/ui.duck';
import AutobizModal from '../../partExchange/components/AutobizModal';
import Container from './MainStyled';
import { withPublicAssets, IpublicAssets, isHomeUrl } from '@utils/url.utils';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../../context/featureSwitchApp';
import { PaymentJourneyTypes } from '../../partExchange/interfaces/Default';
import FilterDuck from '../../redux/filters/filter.duck';
import { useRouter } from 'next/router';
import { rgba } from 'polished';
import AgentToolBar from '@components/AgentToolBar';
import { useUserDuck } from '@hooks/useUserDuck';
import LoaderOverlay from '@components/LoaderOverlay';
import { useEffectOnce } from 'react-use';
import { useExperiment } from '@hooks/useExperiment';
import { PlatformType, PosthogTrigger } from '../../constants/enums';

interface IMain {
    children: JSX.Element[] | JSX.Element;
    description?: string;
    title?: string;
    footerLinks?: IFooterItem[];
    headerLinks?: IHeaderItem[];
    hidden?: boolean;
    publicAssets: IpublicAssets;
    checkout?: boolean;
    canonicalLink?: string;
    paymentJourney?: PaymentJourneyTypes;
    whiteBackground?: boolean;
    hasOpenFilter?: boolean;
}

type MainProps = IMain & SC;

const Main: React.FC<MainProps> = ({
    children,
    title,
    description,
    footerLinks,
    headerLinks,
    hidden,
    publicAssets,
    checkout,
    canonicalLink,
    paymentJourney,
    whiteBackground = false,
    hasOpenFilter = false,
}) => {
    const { t } = useTranslations();
    const { pushTrigger, getPageNameByUrl } = useExperiment();
    const { isUserLoggedInAsDealer, isLoggingOut } = useUserDuck();
    const router = useRouter();
    const pageTitle = `${t('title')} ${title ? `| ${title}` : ''}`;
    const headerTitle = `${t('header.title')}`;
    const headerSubtitle =
        paymentJourney === PaymentJourneyTypes.CASH
            ? `${t('header.subtitle.cash')}`
            : `${t('header.subtitle.finance')}`;
    //APP-19313
    const isLanguageSwitcherEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_LANGUAGE_SWITCHER_ENABLED);
    const isLanguageSwitcherAllowed =
        MULTI_LANGUAGES_ENABLED && isHomeUrl(router.pathname) && isLanguageSwitcherEnabled;
    const isLanguageControlsEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_LANGUAGE_CONTROLS);

    useEffectOnce(() => {
        pushTrigger(PosthogTrigger.PAGE_VIEW, getPageNameByUrl());
        pushTrigger(PosthogTrigger.VERSION, getPageNameByUrl(), PlatformType.SOL);
    });

    return (
        <Container
            style={{
                paddingTop: isLanguageControlsEnabled && !hidden ? '40px' : 0,
                backgroundColor: checkout || whiteBackground ? 'white' : 'initial',
                position: hasOpenFilter ? 'relative' : 'unset',
            }}
        >
            <Head>
                <title key={'title'}>{pageTitle}</title>
                <meta key={'description'} name="description" content={description} />
                <link
                    rel="shortcut icon"
                    href={publicAssets(`/${BRAND}/${isMarketGB && isBrandOV ? 'GB/' : ''}favicon.ico`)}
                />
                {canonicalLink && (
                    <link
                        rel="canonical"
                        href={
                            (typeof window !== 'undefined' ? window?.location?.origin : '') +
                            canonicalLink?.substring(
                                0,
                                canonicalLink.length - +(canonicalLink.lastIndexOf('/') == canonicalLink.length - 1)
                            )
                        }
                    />
                )}
                {/* TODO: Add initial GTM data */}
            </Head>
            {isLanguageControlsEnabled && <LanguageControls />}

            {isLanguageSwitcherAllowed && <LanguageSwitcher />}
            <Header title={headerTitle} subtitle={headerSubtitle} links={headerLinks} />
            {isUserLoggedInAsDealer && <AgentToolBar />}
            {isLoggingOut && <LoaderOverlay loading />}
            <>
                {React.Children.map(children, (child) =>
                    child ? React.cloneElement(child, { isUserLoggedInAsDealer }) : null
                )}
            </>
            <Footer checkout={checkout} items={footerLinks} />
            <Modal />
            {hasOpenFilter && (
                <div
                    className="filterModalBackground"
                    style={{
                        backgroundColor: rgba(0, 0, 0, 0.75),
                        zIndex: 999999,
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                    }}
                />
            )}
            <Alert />
            <AutobizModal />
        </Container>
    );
};

const MapStateToProps = (state: any) => ({
    footerLinks: ContentDuck.getFooter(state),
    headerLinks: ContentDuck.getHeader(state),
    hidden: UIDuck.getOwnState(state).devBarHidden,
    paymentJourney: FilterDuck.getPaymentJourneyType(state),
});

export default connect(MapStateToProps)(withPublicAssets(Main));
